import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/Main'),
    children: [
      {
        path: '/contractdetail',
        name: 'contractdetail',
        component: () => import('@/views/ContractDetailManage/ContractDetail')
      },
      {
        path: '/heyueDetail',
        name: 'heyueDetail',
        component: () => import('@/views/WuziManage/contractInfoManage/heyueDetail')
      },
      {
        path: '/htfwcontractdetail',
        name: 'htfwcontractdetail',
        component: () => import('@/views/FawuHtManage/HtFawuContractDetail')
      },
      {
        path: '/zxfwcontractdetail',
        name: 'zxfwcontractdetail',
        component: () => import('@/views/FawuZxManage/ZxFawuContractDetail')
      }
    ]
  },
  //     {
  //       path: '/contract_manage',
  //       name: 'contract_manage',
  //       component: () => import('@/views/ContractManage/ContractManage')
  //     },
  //     {
  //       path: '/collection',
  //       name: 'collection',
  //       component: () => import('@/views/Collection/Collection')
  //     },
  //     {
  //       path: '/financial_collection',
  //       name: 'financial_collection',
  //       component: () => import('@/views/FinancialCollection/FinancialCollection')
  //     },
  //     {
  //       path: '/material_dispatch',
  //       name: 'material_dispatch',
  //       component: () => import('@/views/MaterialDispatch/MaterialDispatch')
  //     },
  //     {
  //       path: '/outside_contract',
  //       name: 'outside_contract',
  //       component: () => import('@/views/OutsideContract/OutsideContract')
  //     }
  //   ]
  // },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login/Login2')
  },
  {
    path: '/test',
    component: () => import('@/views/ContractManage/test.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
