import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import utils from './assets/js/utils'

// 全局配置
import '@/assets/scss/reset.scss'
import '@/assets/scss/common.css'
import 'element-ui/lib/theme-chalk/index.css'

import http from '@/api/config'

// 第三方包

import ElementUI from 'element-ui'
import axios from 'axios'
// import { util } from 'prettier'
Vue.prototype.$axios = axios
// 配置公共url
// axios.defaults.baseURL = 'http://127.0.0.1:8000' //本地
axios.defaults.baseURL = 'http://114.116.245.59:8000' //服务器

Vue.use(ElementUI)
//引入导出excel组件
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)
Vue.prototype.$http = http

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  // 防止刷新后vuex里丢失标签列表tagList
  store.commit('getMenu')
  let token = utils.getToken()
  // 过滤登录页，防止死循环
  if (!token && to.name !== 'login') {
    next({ name: 'login' })
  } else {
    next()
  }
})

//添加请求拦截器
// axios.interceptors.request.use(config => {
//   config.url = '/ele' + config.url
//   return config
// })
//     let token = utils.getToken()
//     if (token) {
//       config.headers.common['token'] = token
//     }
//     return config
//   },
//   error => {
//     //对请求错误做什么
//     return Promise.reject(error)
//   }
// )

//http reponse响应拦截器
axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          store.commit('clearToken')
          router.replace({ name: 'login' })
      }
    }
  }
)

/* 引入filter文件中的过滤器*/
import * as filters from './assets/js/filter'

/* 注册过滤器 */
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

new Vue({
  router,
  store,
  render: h => h(App),

  created() {
    store.commit('addMenu', router)
  }
}).$mount('#app')
