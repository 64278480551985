// import Cookie from 'js-cookie'

let utils = {
  getDateTime() {
    let myDate = new Date()
    let year = String(myDate.getFullYear())
    let month = myDate.getMonth() + 1
    let day = myDate.getDate()
    let hour = myDate.getHours()
    let minute = myDate.getMinutes()

    if (month < 10) month = '0' + String(month)
    if (day < 10) day = '0' + String(day)
    if (hour < 10) hour = '0' + String(hour)
    if (minute < 10) minute = '0' + String(minute)

    let operateTime = year + '-' + month + '-' + day + ' ' + hour + ':' + minute
    return operateTime
  },
  getDate() {
    let myDate = new Date()
    let year = String(myDate.getFullYear())
    let month = myDate.getMonth() + 1
    let day = myDate.getDate()

    if (month < 10) month = '0' + String(month)
    if (day < 10) day = '0' + String(day)

    let operateTime = year + '-' + month + '-' + day
    return operateTime
  },
  /*'yyyy-MM-dd HH:mm'格式的字符串转日期*/
  stringToDate(str) {
    let tempStrs = str.split(' ')
    let dateStrs = tempStrs[0].split('-')
    let year = parseInt(dateStrs[0], 10)
    let month = parseInt(dateStrs[1], 10) - 1
    let day = parseInt(dateStrs[2], 10)
    let timeStrs = tempStrs[1].split(':')
    let hour = parseInt(timeStrs[0], 10)
    let minute = parseInt(timeStrs[1], 10)
    // second = parseInt(timeStrs[2], 10);
    let date = new Date(year, month, day, hour, minute)
    return date
  },
  //格式化日期时间
  formatDateTime(date, fmt) {
    date = new Date(date)
    if (typeof fmt === 'undefined') {
      fmt = 'yyyy-MM-dd hh:mm:ss'
    }
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    let o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'h+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds()
    }
    for (let k in o) {
      if (new RegExp(`(${k})`).test(fmt)) {
        let str = o[k] + ''
        fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : ('00' + str).substr(str.length))
      }
    }
    return fmt
  },

  // setToken(val) {
  //   Cookie.set('token', val)
  // },
  // clearToken() {
  //   Cookie.remove('token')
  // },
  // getToken() {
  //   let token = Cookie.get('token')
  //   return token
  // },

  // setUserCookie(val) {
  //   let userjson = JSON.stringify(val)
  //   Cookie.set('user', userjson)
  // },

  // clearUserCookie() {
  //   Cookie.remove('user')
  // },

  // getUserCookie() {
  //   if (Cookie.get('user')) return JSON.parse(Cookie.get('user'))
  //   else return
  // },
  // //设置menu的sessionStorage
  // setMenuCookie(val) {
  //   Cookie.set('menu', JSON.stringify(val))
  // },
  // clearMenuCookie() {
  //   Cookie.remove('menu')
  // },
  // getMenuCookie() {
  //   if (Cookie.get('menu')) return JSON.parse(Cookie.get('menu'))
  //   else return
  // },

  // //设置tagList的sessionStorage
  // setTagListCookie(val) {
  //   Cookie.set('tagList', JSON.stringify(val))
  // },
  // clearTagListCookie() {
  //   Cookie.remove('tagList')
  // },
  // getTagListCookie() {
  //   if (Cookie.get('tagList')) return JSON.parse(Cookie.get('tagList'))
  //   else return
  // }

  //设置token的sessionStorage
  setToken(val) {
    sessionStorage.setItem('token', val)
  },
  clearToken() {
    sessionStorage.removeItem('token')
  },
  getToken() {
    return sessionStorage.getItem('token')
  },

  //设置当前用户的sessionStorage
  setUserCookie(val) {
    let userjson = JSON.stringify(val)
    sessionStorage.setItem('user', userjson)
  },
  clearUserCookie() {
    sessionStorage.removeItem('user')
  },
  getUserCookie() {
    return JSON.parse(sessionStorage.getItem('user'))
  },

  //设置当前分公司的sessionStorage
  setCompanyCookie(val) {
    let json = JSON.stringify(val)
    sessionStorage.setItem('company', json)
  },
  clearCompanyCookie() {
    sessionStorage.removeItem('company')
  },
  getCompanyCookie() {
    return JSON.parse(sessionStorage.getItem('company'))
  },

  //设置当前合同列表搜索form的keyword的sessionStorage
  setKeywordCookie(val) {
    let json = JSON.stringify(val)
    sessionStorage.setItem('keyword', json)
  },
  clearKeywordCookie() {
    sessionStorage.removeItem('keyword')
  },
  getKeywordCookie() {
    return JSON.parse(sessionStorage.getItem('keyword'))
  },

  //设置当前合同所在的page，用于从合同详情页返回时使用
  setPageCookie(val) {
    let json = JSON.stringify(val)
    sessionStorage.setItem('page', json)
  },
  clearPageCookie() {
    sessionStorage.removeItem('page')
  },
  getPageCookie() {
    return JSON.parse(sessionStorage.getItem('page'))
  },

  //设置menu的sessionStorage
  setMenuCookie(val) {
    sessionStorage.setItem('menu', JSON.stringify(val))
  },
  clearMenuCookie() {
    sessionStorage.removeItem('menu')
  },
  getMenuCookie() {
    return JSON.parse(sessionStorage.getItem('menu'))
  },

  //设置tagList的sessionStorage
  setTagListCookie(val) {
    sessionStorage.setItem('tagList', JSON.stringify(val))
  },
  clearTagListCookie() {
    sessionStorage.removeItem('tagList')
  },
  getTagListCookie() {
    return JSON.parse(sessionStorage.getItem('tagList'))
  },
  //从字符串中取出字母子串
  getAlphaInString(str) {
    let str1 = str.trim()
    str1 = str1.match(/^[a-z|A-Z]+/gi)
    if (!str1) return '---'
    return str1[0]
  },
  //从字符串中取出数字子串
  getNumInString(str) {
    let str1 = str.trim()
    str1 = str1.match(/^[a-z|A-Z]+/gi)
    if (!str1) return '---'
    return str1[0]
  },
  //打印页面内容
  printContent(printContent) {
    let wpt = document.getElementById(printContent)
    let newContent = wpt.innerHTML
    let oldContent = document.body.innerHTML

    document.body.innerHTML = newContent
    window.print() //打印方法
    window.location.reload()
    document.body.innerHTML = oldContent
  }
}

export default utils
